import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { bootstrapApplication } from '@angular/platform-browser';
import { Settings } from 'luxon';
import { AppComponent } from './app/app.component';
import { routes } from './app/app.routes';
import { provideCore } from './app/core/core';

registerLocaleData(localeDe);

Settings.defaultZone = 'utc';
Settings.defaultLocale = 'de';

bootstrapApplication(AppComponent, {
  providers: [provideCore({ routes })],
}).catch((err) => console.error(err));
