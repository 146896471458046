import { AsyncPipe } from '@angular/common';
import { Component, OnInit, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ConfigService } from './core/config.service';
import { ThemeManagerService } from './core/theme-manager.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterOutlet, AsyncPipe],
})
export class AppComponent implements OnInit {
  readonly cs = inject(ConfigService);
  readonly tms = inject(ThemeManagerService);

  ngOnInit(): void {
    this.tms.applyDefault();
  }
}
