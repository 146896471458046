import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthRepository } from './repositories/auth.repository';

export class AuthenticatedGuardError extends Error {}

@Injectable()
export class AuthenticatedGuard {
  private readonly authRepo = inject(AuthRepository);
  private readonly router = inject(Router);

  canMatch(): Observable<boolean> {
    return this.authRepo.isLoggedIn$.pipe(
      switchMap((isLoggedIn) => {
        if (isLoggedIn === true) {
          return of(true);
        } else {
          return this.router.navigate(['/auth', 'login']);
          // return throwError(() => new AuthenticatedGuardError('Unauthorized'));
        }
      }),
    );
  }
}
