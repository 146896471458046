import { HttpClient } from '@angular/common/http';
import { TranslateLoader } from '@ngx-translate/core';
import { set, trim } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class AppTranslateLoader implements TranslateLoader {
  constructor(private readonly http: HttpClient) {}
  getTranslation(lang: string): Observable<Record<string, string>> {
    return this.http.get(`/assets/i18n/${lang}.csv`, { responseType: 'text' }).pipe(
      map((translationsCSV: string) =>
        translationsCSV.split('\n').reduce((acc: Record<string, string>, line: string) => {
          const match = line.match(/(?:[^;"]+|"[^"]*")+/g);
          if (match && match.length) {
            const [key, translation] = match;
            set(acc, key, trim(translation, '"'));
          }
          return acc;
        }, {}),
      ),
    );
  }
}
