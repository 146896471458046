import { Observable, tap } from 'rxjs';
import { ApiService } from '../generated/api/services';
import { IConfig } from './config.interfaces';
import { ConfigService } from './config.service';

export function configInitFactory(cs: ConfigService, api: ApiService): () => Observable<IConfig> {
  return () =>
    cs.init().pipe(
      tap((config) => {
        api.rootUrl = config.apiUrl;
      }),
    );
}
