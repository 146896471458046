import { MatDialogConfig } from '@angular/material/dialog';

const matDialogBaseConfig: MatDialogConfig = {
  position: {
    right: '0',
  },
  panelClass: ['animate__animated', 'animate__slideInRight', 'animate__faster'],
  height: '100vh',
  disableClose: true,
  closeOnNavigation: true,
  hasBackdrop: true,
  autoFocus: 'first-tabbable',
};

const MatConstants: Record<string, MatDialogConfig> = {
  dialogConfig50vwcenter: {
    ...matDialogBaseConfig,
    width: '75vw',
    position: {
      left: '25vw',
      right: '25vw',
    },
  },
  dialogConfig50vw: {
    ...matDialogBaseConfig,
    width: '50vw',
  },
  dialogConfig75vw: {
    ...matDialogBaseConfig,
    width: '75vw',
  },
  dialogConfig100vw: {
    ...matDialogBaseConfig,
    width: '100vw',
    closeOnNavigation: false,
  },
  dialogConfigCentered: {
    panelClass: ['na-centered-dialog', 'animate__animated', 'animate__fadeIn', 'animate__faster'],
    width: '600px',
    disableClose: true,
    closeOnNavigation: true,
    hasBackdrop: true,
  },
};

export { MatConstants };
