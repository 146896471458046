import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { ConfigService } from './config.service';

@Injectable()
export class AppTitleStrategy extends TitleStrategy {
  constructor(
    private readonly title: Title,
    readonly cs: ConfigService,
  ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    const title = this.buildTitle(routerState);
    if (title !== undefined) {
      this.title.setTitle(`${title} - ${this.cs.config.title}`);
    }
  }
}
