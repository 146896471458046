import { IMAGE_CONFIG } from '@angular/common';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import {
  DEFAULT_CURRENCY_CODE,
  ENVIRONMENT_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  importProvidersFrom,
  inject,
  provideAppInitializer,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  Routes,
  TitleStrategy,
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withInMemoryScrolling,
  withRouterConfig,
} from '@angular/router';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ApiModule } from '../generated/api/api.module';
import { ApiService } from '../generated/api/services';
import { AppMissingTranslationHandler } from '../i18n/missing-translation-handler';
import { AppTranslateLoader } from '../i18n/translation-loader';
import { MaterialModule } from '../material.module';
import { API_INTERCEPTOR_PROVIDER, ApiInterceptor } from './api.interceptor';
import { AuthenticatedGuard } from './authenticated.guard';
import { configInitFactory } from './config.init.factory';
import { ConfigService } from './config.service';
import { GlobalErrorHandler } from './global-error-handler.service';
import { AgentRepository } from './repositories/agent.repository';
import { AuthRepository } from './repositories/auth.repository';
import { InstanceWageRepository } from './repositories/instance-wage.repository';
import { MeRepository } from './repositories/me.repository';
import { SettingsRepository } from './repositories/settings.repository';
import { SettingsService } from './settings.service';
import { AppTitleStrategy } from './title.strategy';

export interface CoreOptions {
  routes: Routes;
}

export function provideCore({ routes }: CoreOptions) {
  return [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withEnabledBlockingInitialNavigation(),
      withInMemoryScrolling({
        scrollPositionRestoration: 'disabled',
      }),
      withRouterConfig({
        onSameUrlNavigation: 'ignore',
      }),
    ),
    {
      provide: Window,
      useValue: window,
    },
    importProvidersFrom(
      ApiModule.forRoot({
        rootUrl: undefined,
      }),
      MaterialModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useClass: AppTranslateLoader,
          deps: [HttpClient],
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: AppMissingTranslationHandler,
        },
        defaultLanguage: 'de',
        useDefaultLang: true,
      }),
    ),
    ApiInterceptor,
    API_INTERCEPTOR_PROVIDER,
    AuthenticatedGuard,
    AuthRepository,
    ConfigService,
    AgentRepository,
    SettingsRepository,
    InstanceWageRepository,
    SettingsService,
    MeRepository,
    { provide: LOCALE_ID, useValue: 'de-DE' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
    },
    {
      provide: TitleStrategy,
      useClass: AppTitleStrategy,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    {
      provide: IMAGE_CONFIG,
      useValue: { disableImageSizeWarning: true, disableImageLazyLoadWarning: true },
    },
    // perform initialization, has to be last
    provideAppInitializer(() => {
      const initializerFn = configInitFactory(inject(ConfigService), inject(ApiService));
      return initializerFn();
    }),
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue() {
        // add init logic here...
        // kickstart processes, trigger initial requests or actions, ...
      },
    },
  ];
}
