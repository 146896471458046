import { ErrorHandler, Injectable } from '@angular/core';
import { AuthenticatedGuardError } from './authenticated.guard';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor() {}

  handleError(error: unknown) {
    // Check if it's an error from an HTTP response
    console.log('error', error);

    if (error instanceof AuthenticatedGuardError) {
      console.log('AuthenticatedGuardError', this);
    }
    console.error('Error from global error handler', error);
  }
}
