import { Routes } from '@angular/router';
import { AuthenticatedGuard } from './core/authenticated.guard';

export const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.routes'),
    title: 'Login',
  },
  {
    path: '',
    canMatch: [AuthenticatedGuard],
    title: 'App',
    loadComponent: () =>
      import('./features/authenticated/authenticated.component').then((mod) => mod.AuthenticatedComponent),
    children: [
      {
        path: 'd',
        loadChildren: () => import('./features/dashboard/dashboard.routes'),
        title: 'Dashboard',
      },
      {
        path: 't',
        loadChildren: () => import('./features/team/team.routes'),
        title: 'Team',
      },
      {
        path: 'i',
        loadChildren: () => import('./features/instance/instance.routes'),
        title: 'Fälle',
      },
      {
        path: 'r',
        loadChildren: () => import('./features/invoice/invoice.routes'),
        title: 'Rechnungen',
      },
      {
        path: 'l',
        loadChildren: () => import('./features/timelog/timelog.routes'),
        title: 'Zeiterfassung',
      },
      {
        path: 's',
        loadChildren: () => import('./features/settings/settings.routes'),
        title: 'Einstellungen',
      },
      {
        path: 'st',
        loadChildren: () => import('./features/stats/stats.routes'),
        title: 'Statistiken',
      },
      {
        path: 'a',
        loadChildren: () => import('./features/agent/agent.routes'),
        title: 'Kontakte',
      },
      {
        path: 'g',
        loadChildren: () => import('./features/region/region.routes'),
        title: 'Regionen',
      },
      {
        path: 'c',
        loadChildren: () => import('./features/accounting/accounting.routes'),
        title: 'Abrechnungen',
      },
      {
        path: '**',
        redirectTo: '/d',
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/auth/login',
  },
];
